import React, { useState, useEffect } from "react";

export default function Timer() {
  let [seconds, setSec] = useState(0);
  let [minutes, setMin] = useState(2);
  let [state, setstate] = useState("Start");
  let [begin, setbegin] = useState(true);
  let [hide, control] = useState(true);

  const stateChange = e => {
    if (begin) {
      setstate("Stop");
      setbegin(!begin);
    }

    if (!begin) {
      setstate("Start");
      setbegin(!begin);
    }
  };
  useEffect(_ => {
    if (!begin) {
      const intervalToken = setInterval(_ => {
        if (seconds === 0) {
          if (minutes > 0) {
            setSec(59);
            setMin(minutes - 1);
          } else {
            alert(
              "\n       ===================  T I M E  O U T  ===================="
            );
            setbegin(true);
            control(false);
          }
        } else setSec(seconds - 1);
      }, 1000);
      return _ => {
        clearInterval(intervalToken);
      };
    }
  });

  const reset = e => {
    setSec(0);
    setMin(2);
    setbegin(true);
    control(true);
    setstate("Start");
  };
  return (
    <div>
      <div id="fn">
        <span>
          {" "}
          {minutes < 10 ? "0" + minutes : minutes}
          <sub>m </sub>
        </span>
        <span>
          {" "}
          : {seconds < 10 ? "0" + seconds : seconds}
          <sub>s </sub>
        </span>
      </div>
      <div id="buttons">
        {hide ? (
          <button className="start" onClick={stateChange}>
            {state}
          </button>
        ) : (
          ""
        )}
        <button className="reset" onClick={reset}>
          Reset
        </button>
      </div>
    </div>
  );
}
