import React, { useState, useEffect } from "react";

export default function StopWatch() {
  let [seconds, setSec] = useState(0);
  let [minutes, setMin] = useState(0);
  let [hours, setHrs] = useState(0);
  let [state, setstate] = useState("Start");
  let [begin, setbegin] = useState(true);
  let [show, setstatus] = useState(0);

  const stateChange = e => {
    if (begin) {
      setstate("Stop");
      setbegin(!begin);
      //console.log(0);
    }

    if (!begin) {
      setstate("Start");
      setbegin(!begin);
      //console.log(1);
    }
  };
  useEffect(_ => {
    if (!begin) {
      const intervalToken = setInterval(_ => {
        setSec((seconds + 1) % 60);
        if (seconds === 59) {
          if (show !== 2) setstatus(1);
          setMin((minutes + 1) % 60);
          if (minutes === 59) {
            setstatus(2);
            setHrs(hours + 1);
          }
        }
      }, 1000);
      return _ => {
        clearInterval(intervalToken);
      };
    }
  });

  const reset = e => {
    setSec(0);
    setMin(0);
    setHrs(0);
    setstatus(0);
    setbegin(true);
    setstate("Start");
  };
  return (
    <div>
      <div id="fn">
        {show === 2 ? (
          <span>
            {hours < 10 ? "0" + hours : hours}
            <sub>h </sub> :{" "}
          </span>
        ) : (
          ""
        )}

        {show !== 0 ? (
          <span>
            {minutes < 10 ? "0" + minutes : minutes}
            <sub>m </sub> :{" "}
          </span>
        ) : (
          ""
        )}

        <span>
          {seconds < 10 ? "0" + seconds : seconds}
          <sub>s </sub>
        </span>
      </div>

      <div id="buttons">
        <button className="start" onClick={stateChange}>
          {state}
        </button>
        <button className="reset" onClick={reset}>
          Reset
        </button>
      </div>
    </div>
  );
}
