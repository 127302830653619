import React, { useState } from "react";
import Timer from "./Timer";
import StopWatch from "./Stopwatch";
import "./styles.css";

export default function App() {
  let [timer, switchTimer] = useState(false);
  let [stopwatch, switchSW] = useState(true);
  const toggleT = e => {
    switchTimer(true);
    switchSW(false);
  };
  const toggleSw = e => {
    switchTimer(false);
    switchSW(true);
  };
  return (
    <>
      <div id="container">
        <button id="header" onClick={toggleT}>
          {timer ? <div class="active">TIMER</div> : "TIMER"}
        </button>
        <button id="header" className="sw" onClick={toggleSw}>
          {stopwatch ? <div className="active">STOPWATCH</div> : "STOPWATCH"}
        </button>
        {stopwatch ? <StopWatch /> : ""}
        {timer ? <Timer /> : ""}
      </div>
    </>
  );
}
